.message {
  min-width: 80px;
  /* min-height: 60px; */
  height: fit-content;
  width: fit-content;
  max-width: calc(100% - 30px - 100px);
  background-color: white;
  color: rgb(72, 72, 72);
  display: flex;
  border-radius: 7px;
  flex-direction: column;
  align-self: flex-start;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  z-index: 1;
  border: 1px solid rgb(226, 226, 226);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.095);
  margin-left: 30px;
}

.message:nth-child(2n) {
  align-self: flex-end;
  margin-left: 0;
  margin-right: 30px;
}

.robot_name {
  padding-bottom: 8px;
  color: orange;
  font-weight: 900;
  word-wrap: break-word;
}

.message:nth-child(2n) .robot_name {
  color: green;
}

.message .loading div {
  height: 8px;
  width: 8px;
  /* background-color: aquamarine; */
  border-radius: 10px;
  /* animation: jumping_dots 1s; */
}

.message .loading {
  padding-top: 8px;
  display: flex;
  gap: 2px;
}

.loading .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6cad96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.loading .dot:nth-child(1) {
  animation-delay: 200ms;
}
.loading .dot:nth-child(2) {
  animation-delay: 300ms;
}
.loading .dot:nth-child(3) {
  animation-delay: 400ms;
}
.loading .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6cad96;
  }
  28% {
    transform: translateY(-7px);
    background-color: #9ecab9;
  }
  44% {
    transform: translateY(0px);
    background-color: #b5d9cb;
  }
}
