@import url("https://fonts.cdnfonts.com/css/old-computer-st");
@import url("https://fonts.googleapis.com/css2?family=Madimi+One&family=Rock+3D&family=Rubik+Iso&family=Workbench&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Madimi+One&family=Rock+3D&family=Rubik+Iso&family=Silkscreen:wght@400;700&family=Workbench&display=swap");

.messageResponse {
  height: fit-content;
  width: fit-content;
  background-color: white;
  max-width: calc(100% - 30px - 100px);
  display: flex;
  border-radius: 7px;
  flex-direction: column;
  padding: 10px;
  align-self: flex-end;
  color: rgb(72, 72, 72);
  border: 1px solid rgb(226, 226, 226);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.095);
  margin-right: 30px;

  /* align-items: flex-end; */
  /* margin-left: 40px; */
  /* font-family: 'Old computer St', sans-serif; */
  /* font-family: "Workbench", sans-serif; */
}

.user_name {
  /* font-family: "Silkscreen", sans-serif; */
  color: green;
  font-weight: 900;
}
